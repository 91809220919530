<template>
  <v-layout
    row
    wrap
  >
    <v-flex xs12>
      <v-btn
        :to="{name: 'admin:candidate:applications', params: {uuid: $route.params.uuid}}"
        color="warning"
      >
        <v-icon left>
          undo
        </v-icon>
        Volver
      </v-btn>
    </v-flex>
    <v-flex
      xs12
    >
      <v-data-table
        :headers="headers"
        :items="evaluations"
        no-data-text="No se encontraron evaluaciones."
        hide-actions
        disable-pagination
      >
        <template
          slot="headers"
        >
          <tr>
            <th
              v-for="header in headers"
              :key="header.text"
              align="left"
              style="font-size: small; font-weight: bold"
            >
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template
          slot="items"
          slot-scope="props"
        >
          <td>{{ props.item.NombreCompleto }}</td>
          <td>{{ props.item.Cargo }}</td>
          <td>{{ props.item.Resultado }}</td>
          <td>{{ props.item.FechaEntrevista }}</td>
          <td>{{ props.item.NombreCoordinador }}</td>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from 'axios';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';

export default {
  name: 'MMDigitalEvaluationHistory',
  mixins: [JWTConfigMixin],
  data() {
    return {
      evaluations: [],
      loading: false,
      error: false,
      headers: [
        {
          text: 'Nombre Completo',
          value: 'NombreCompleto',
        },
        {
          text: 'Cargo',
          value: 'Cargo',
        },
        {
          text: 'Resultado',
          value: 'Resultado',
        },
        {
          text: 'Fecha Entrevista',
          value: 'sortable_date',
        },
        {
          text: 'Nombre Coordinador',
          value: 'NombreCoordinador',
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      const endpoint = this.$store.state.endpoints.candidates.mmdigitalEvaluations(
        this.$route.params.uuid,
      );
      this.$store.commit('setLoading');
      axios.get(endpoint, this.jwtConfig).then(response => {
        if (response.data.error) {
          this.evaluations = [];
        } else {
          this.evaluations = response.data.map(data => {
            const copy = { ...data };
            const [day, month, year] = copy.FechaEntrevista.split('-');
            copy.sortable_date = `${year}-${month}-${day}`;
            return copy;
          });
        }
        this.$store.commit('unsetLoading');
      });
    });
  },
};
</script>
